<template>
  <div class="smart">
    <div class="daohang">
      <div
        :class="{ topact: topact == 1 }"
        class="toptitle"
        @click="changeop(1)">
        智慧跑步
      </div>
      <!-- <div
        :class="{ topact: topact == 2 }"
        class="toptitle"
        @click="changeop(2)">
        参赛报名
      </div> -->
    </div>
    <div class="tab_bix" v-if="topact == 1">
      <div class="content">
        <div class="task_list">
          <div class="tab_my">
            <h4
              :class="{ active: index == currentIndex }"
              v-for="(item, index) in statusList"
              :key="index"
              @click="handleTabChange(item.id, index)">
              {{ item.title }}
            </h4>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="task_list">
          <div class="task_ner flexSb">
            <div
              class="task_ner_list"
              v-for="(item, index) in list"
              :key="index"
              :class="{ last: (index + 1) % 2 == 0 }">
              <div
                class="status"
                :class="{
                  status_ing: item.status == 1,
                  status_end: item.status == 2,
                }">
                {{
                  item.status == 0
                    ? "未开始"
                    : item.status == 2
                    ? "已结束"
                    : item.status == 1
                    ? "进行中"
                    : ""
                }}
              </div>
              <div class="name_time">
                <div class="name">{{ item.name }}</div>

                <div class="flexSt">
                  <!-- <div @click="handleToPri(item)" v-if="item.status != 2" class="btn_proint btn btn_ck">
                    打印路线
                  </div>  v-if="item.status == 1"-->
                  <el-button
                    v-if="item.status == 1"
                    @click="handleToGo('/realTimeLLine', item)"
                    round
                    type="success">
                    进入赛事 </el-button
                  ><el-button
                    v-if="item.status == 2"
                    @click="handleToGo('/realTimeScore', item)"
                    round
                    type="primary">
                    查看成绩
                  </el-button>
                </div>
              </div>

              <div class="name_ii">
                {{ item.start_time }}-{{ item.end_time }}
              </div>
              <!-- <div class="name_ii flexSb">
                <div>班级：{{ item.class_name }}</div>

                <div>人数：{{ item.count_num }}人</div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="pla flexSe">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="change"
            :current-page.sync="pageInfo.page"
            :page-size="pageInfo.per_page"
            :total="pageInfo.total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { orienteer, orienteernew } from "@/api/games/index.js";
export default {
  data() {
    return {
      topact: 1,
      list: [],
      statusList: [
        {
          title: "全部",
          id: "",
        },
        {
          title: "未开始",
          id: "0",
        },
        {
          title: "进行中",
          id: "1",
        },
        {
          title: "已结束",
          id: "2",
        },
      ],
      currentIndex: "0",
      pageInfo: {
        page: 1,
        total: 0,
        per_page: 6,
      },
    };
  },
  created() {
    this.handleGetlistDx();
  },
  methods: {
    handleToGo(url, val) {
      this.$router.push(url + "?val=" + JSON.stringify(val));
    },
    changeop(y) {
      this.topact = y;
    },
    async handleGetlistDx() {
      let data = {
        per_page: this.pageInfo.per_page,
        page: this.pageInfo.page,
      };
      if (this.status) {
        data.status = this.status;
      }
      await orienteernew(data).then((res) => {
        this.list = res.data.data;
        this.pageInfo.total = res.data.total;
      });
    },
    change(val) {
      this.pageInfo.page = val;
      this.handleGetlistDx();
    },
    handleToPri(item) {
      this.$router.push("./pointmap?id=" + item.id);
    },
    handleTabChange(id, index) {
      this.currentIndex = index;
      this.status = id;
      this.pageInfo.page = 1;
      this.handleGetlistDx();
    },
  },
};
</script>
<style lang="less" scoped>
.flexSb {
  //   width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //   padding: 10px;
  padding-right: 55px;
  padding-bottom: 0;
  box-sizing: border-box;
}

.name_ii {
  font-size: 22px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  margin-left: 25px;
}

.header_box {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  margin-bottom: 40px;

  img {
    width: 23px;
    height: 23px;
    margin-right: 10px;
    cursor: pointer;
  }
}

.el-pagination {
  //   position: absolute;
  //   bottom: 40px;
  //   right: 53px;
  margin-top: 50px;
  padding-right: 50px;
}

.btn_proint {
  margin-right: 20px;
}

.task {
  height: 100%;
  position: relative;

  .content {
    // margin-top: 30px;
    // padding-top: 40px;
    // padding-left: 55px;
    // padding-right: 55px;
    // display: flex;
    .tab_my {
      display: flex;
      margin-bottom: 20px;

      h4 {
        font-size: 20px;
        margin-right: 20px;
        cursor: pointer;
      }

      .active {
        color: #226bff;
      }
    }

    .task_list {
      flex: 2;
      // padding-left: 83px;

      .task_status {
        display: flex;

        .status_btn {
          // -----------
          width: 123px;
          height: 65px;
          font-size: 30px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          line-height: 65px;
          text-align: center;
          border-radius: 40px;
          margin-right: 40px;
          color: #999;
        }

        .status_btn_sel {
          color: #ffffff;
          background: linear-gradient(97deg, #ffea86 0%, #ffb23c 100%);
        }
      }

      .task_ner {
        // margin-top: 54px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .task_ner_list {
          width: 48%;
          //   height: 269px;
          background: #ffffff;
          box-shadow: 4px 16px 48px 0px rgba(111, 143, 234, 0.1);
          //   border-radius: 30px 90px 45px 45px;
          border-radius: 30px;
          margin-bottom: 20px;
          margin-right: 20px;
          padding-bottom: 20px;

          .status {
            width: 106px;
            height: 43px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            line-height: 43px;
            text-align: center;
            border-radius: 30px 0px 50px 0px;
            // ---------
            color: #ffffff;
            background: linear-gradient(33deg, #ffc979 0%, #ff9900 100%);
          }

          .status_ing {
            background: linear-gradient(33deg, #6ee4ab 0%, #2aca7d 100%);
          }

          .status_end {
            background: linear-gradient(38deg, #ff9797 0%, #ff6666 100%);
          }

          .status_ed {
          }

          .name_time {
            display: flex;
            align-items: center;
            padding-left: 25px;
            padding-right: 55px;
            box-sizing: border-box;
            justify-content: space-between;
            // padding-top: 40px;

            .name {
              font-size: 22px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #444444;
            }

            .btn {
              cursor: pointer;
              width: 126px;
              height: 38px;
              line-height: 38px;
              background: linear-gradient(142deg, #89e2fe 0%, #63a4ff 100%);
              border-radius: 36px;
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              text-align: center;
            }

            .btn_ck {
              background: #13c654;
            }

            .btn_wks {
              background: #226bff;
              // background-color: rgba(213, 213, 213, 1);
            }

            .btn_ckcs {
              background: #ff7d56;
            }
          }

          .time {
            padding-left: 25px;
            font-size: 22px;
            margin-top: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            box-sizing: border-box;
          }

          .classn_xl {
            // margin-top: 10px;
            display: flex;
            padding-left: 25px;
            // padding-right: 55px;
            justify-content: flex-start;
            padding-top: 10px;
            font-size: 22px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;

            .classn {
              width: 50%;
            }
          }
        }

        .last {
          margin-right: 0;
          width: 49.3%;
        }
      }
    }
  }

  .task_ner {
    // height: 1100px;

    // border: 1px solid pink;
  }

  .btn_n {
    // width: 850px;
    width: 400px;
    height: 68px;
    background: linear-gradient(142deg, #89e2fe 0%, #63a4ff 100%);
    box-shadow: 0px 5px 48px -8px rgba(111, 143, 234, 0.46);
    border-radius: 38px;
    color: #fff;
    text-align: center;
    line-height: 68px;
    position: fixed;
    bottom: 45px;
    left: 50%;
    transform: translate(-50%);
    font-size: 22px;
    cursor: pointer;
    margin: 0 auto;
    margin-top: 30px;
  }

  .btn_right {
    image {
      width: 118px;
      height: 126px;
      position: fixed;
      right: 0px;
      bottom: 117px;
    }
  }

  .poup_content {
    position: relative;

    .poup_header {
      text-align: center;
      padding-top: 41px;
      box-sizing: border-box;
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #444444;
      margin-bottom: 134px;
    }

    .close {
      width: 32px;
      height: 32px;
      position: absolute;
      right: 43px;

      top: 50px;
    }

    .poup_main {
      padding: 0 139px;
      box-sizing: border-box;

      .poup_main_item {
        image {
          width: 151px;
          height: 151px;
          margin-bottom: 24px;
        }

        font-size: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #444444;
        text-align: center;
      }
    }

    .poup_btn {
      width: 510px;
      height: 68px;
      background: linear-gradient(142deg, #89e2fe 0%, #63a4ff 100%);
      box-shadow: 0px 5px 48px -8px rgba(111, 143, 234, 0.46);
      border-radius: 38px;
      line-height: 68px;
      text-align: center;
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      margin: 0 auto;
      margin-top: 82px;
    }
  }
}

.smart {
  width: 1680px;
  height: 100%;
  background: #f2f7ff;
  border-radius: 20px;
  padding: 20px 20px 20px 40px;
  position: relative;
  .daohang {
    display: flex;
    font-size: 20px;
    cursor: pointer;

    .toptitle {
      color: #000000;
      font-weight: 700;
      margin-right: 20px;
    }
  }
  .topact {
    color: #226bff !important;
  }
  .content {
    // margin-top: 30px;
    // padding-top: 40px;
    // padding-left: 55px;
    // padding-right: 55px;
    // display: flex;
    .tab_my {
      display: flex;
      margin-bottom: 20px;

      h4 {
        font-size: 20px;
        margin-right: 20px;
        cursor: pointer;
      }

      .active {
        color: #226bff;
      }
    }

    .task_list {
      flex: 2;
      // padding-left: 83px;

      .task_status {
        display: flex;

        .status_btn {
          // -----------
          width: 123px;
          height: 65px;
          font-size: 30px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          line-height: 65px;
          text-align: center;
          border-radius: 40px;
          margin-right: 40px;
          color: #999;
        }

        .status_btn_sel {
          color: #ffffff;
          background: linear-gradient(97deg, #ffea86 0%, #ffb23c 100%);
        }
      }

      .task_ner {
        // margin-top: 54px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .task_ner_list {
          width: 48%;
          //   height: 269px;
          background: #ffffff;
          box-shadow: 4px 16px 48px 0px rgba(111, 143, 234, 0.1);
          //   border-radius: 30px 90px 45px 45px;
          border-radius: 30px;
          margin-bottom: 20px;
          margin-right: 20px;
          padding-bottom: 20px;

          .status {
            width: 106px;
            height: 43px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            line-height: 43px;
            text-align: center;
            border-radius: 30px 0px 50px 0px;
            // ---------
            color: #ffffff;
            background: linear-gradient(33deg, #ffc979 0%, #ff9900 100%);
          }

          .status_ing {
            background: linear-gradient(33deg, #6ee4ab 0%, #2aca7d 100%);
          }

          .status_end {
            background: linear-gradient(38deg, #ff9797 0%, #ff6666 100%);
          }

          .status_ed {
          }

          .name_time {
            display: flex;
            align-items: center;
            padding-left: 25px;
            padding-right: 55px;
            box-sizing: border-box;
            justify-content: space-between;
            // padding-top: 40px;

            .name {
              font-size: 22px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #444444;
            }

            .btn {
              cursor: pointer;
              width: 126px;
              height: 38px;
              line-height: 38px;
              background: linear-gradient(142deg, #89e2fe 0%, #63a4ff 100%);
              border-radius: 36px;
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              text-align: center;
            }

            .btn_ck {
              background: #13c654;
            }

            .btn_wks {
              background: #226bff;
              // background-color: rgba(213, 213, 213, 1);
            }

            .btn_ckcs {
              background: #ff7d56;
            }
          }

          .time {
            padding-left: 25px;
            font-size: 22px;
            margin-top: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            box-sizing: border-box;
          }

          .classn_xl {
            // margin-top: 10px;
            display: flex;
            padding-left: 25px;
            // padding-right: 55px;
            justify-content: flex-start;
            padding-top: 10px;
            font-size: 22px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;

            .classn {
              width: 50%;
            }
          }
        }

        .last {
          margin-right: 0;
          width: 49.3%;
        }
      }
    }
  }
}
</style>
